import './influencerPagination.scss';
import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';

interface InfluencerPaginationProps {
  totalItems: number;
  pageSize: number;
  currentPage?: number;
  lastPageElement?: React.ReactElement
  // eslint-disable-next-line no-unused-vars
  onPageChange(page: number): void;
}

export const InfluencerPagination: React.FC<InfluencerPaginationProps> = ({ totalItems, pageSize, currentPage, lastPageElement, onPageChange }: InfluencerPaginationProps) => {
  const [page, setPage] = useState(1);

  useEffect(() => setPage(currentPage || 1), [currentPage])

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    onPageChange(page);
  }

  const pageCount = Math.ceil(totalItems / pageSize);
  const isLastPage = page == pageCount

  return (
    <>
      { isLastPage && lastPageElement}
      <Pagination count={pageCount} page={page} onChange={handlePageChange} color="primary" className='pagination'/>
    </>
  )
};