import { useGet } from 'shared/utils/api';

// TODO url to env
const BASE_API = `${process.env.REACT_APP_BASE_API}/influencers/{id}/prices`;
const generateApi = (influencerId: string) => BASE_API.replace('{id}', influencerId)

interface InfluencerPriceRangesForAdTypesResponse {
  adType: string,
  minPrice?: number,
  maxPrice?: number
}

const useGetInfluencerPriceRangesForAdTypes = (influencerId: string) =>
  useGet<undefined, InfluencerPriceRangesForAdTypesResponse[]>(`${generateApi(influencerId)}/ranges-for-ad-types/`);

export { useGetInfluencerPriceRangesForAdTypes, InfluencerPriceRangesForAdTypesResponse };