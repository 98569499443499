import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InfluencerLongTextBox } from './InfluencerLongTextBox';
import { formatNumber, formatMoney, formatDate } from 'shared/utils';
import { InfluencerLink } from 'components/buttons';

type Variant = 'date' | 'longText' | 'money' | 'simple' | 'link'

interface InfluencerAttributeValuePair {
  attribute?: string,
  textAlign?: 'center' | 'start' | 'end',
  justifyContent?:  'space-between' | 'center' | 'start' | 'end',
  spaceBetween?: boolean,
  value?: string | number
  valueLabel?: string
  variant?: Variant
}

const InfluencerAttributeValuePair = ({attribute, textAlign, justifyContent, spaceBetween, value, valueLabel, variant='simple' }: InfluencerAttributeValuePair) => {

  if (!value) {
    return <></>
  }
  
  const fomratedNumberElseValue = typeof value === 'number' ? formatNumber(value) : value
  const formatedMoneyElseValue = formatMoney(value)
  const formatedDateElseValue = typeof value === 'string' ? formatDate(value) : value

  return (
    <Box textAlign={textAlign ?? 'start'} justifyContent={spaceBetween ? 'space-between' : justifyContent ?? 'start'} marginY={1} alignItems='center' display='flex' width={1}>
      {attribute && <Typography variant='subtitle2' display='inline-block' paddingRight={1}>{attribute}: </Typography>}
      <Box marginY={1}>
        {{
          'date': <Typography variant='body2' display='inline-block'>{formatedDateElseValue}</Typography>,
          'longText': <InfluencerLongTextBox maxLength={100} text={value as string} />,
          'money': <Typography variant='body2' display='inline-block'>{formatedMoneyElseValue}</Typography>,
          'simple': <Typography variant='body2' display='inline-block'>{fomratedNumberElseValue}</Typography>,
          'link': <InfluencerLink hrefToOpenInNewTab={value as string} label={valueLabel || attribute || 'link'} />
        }[variant]}
      </Box>
    </Box>
  );
};

export { InfluencerAttributeValuePair }