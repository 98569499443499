import { Button } from '@mui/material';
import React from 'react';

interface InfluencerLinkProps {
  label: string,
  href?: string,
  hrefToOpenInNewTab?: string,
}

export const InfluencerLink = (props: InfluencerLinkProps) => {
  const onClick = () => {
    if (props.hrefToOpenInNewTab) {
      window.open(props.hrefToOpenInNewTab, '_blank')
    }
  }

  return (
    <Button
      sx={{ textTransform: 'none' }}
      variant="text"
      href={props.href}
      fullWidth
      onClick={onClick}
    >
      {props.label}
    </Button>
  )
}