import React, { useEffect, useState } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';
import { InfluencerAutocomplete, InfluencerAutocompleteWithTags, InfluencerButton, InfluencerCheckbox, InfluencerSelect, InfluencerSlider, InfluencerSubmitButton, InfluencerTextField } from 'components';
import './influencerSearchForm.scss';
import { FormProps } from 'shared/utils';
import { useGetPriceRange, useGetTopics } from 'shared/hooks';
import { useFormContext } from 'react-hook-form';

const INFLUENCER_FOLLOWERS_INTERVAL_DICT: { [key: string]: number[]} = {
  '0 - 1k': [0, 1000],
  '1k - 10k': [1000, 10000],
  '10k - 100k': [10000, 100000],
  '100k - 1m': [100000, 1000000],
  '1m - 10m': [1000000, 10000000],
  '10m - 100m': [10000000, 100000000],
  '100m - 1b': [100000000, 1000000000],
}

const INFLUENCERS_SORT_BY_DICT: { [key: string]: string } = {
  'Feedbacks Count ↑': 'feedbacks_count_asc',
  'Feedbacks Count ↓': 'feedbacks_count_desc',
  'Followers Count ↑': 'follower_count_asc',
  'Followers Count ↓': 'follower_count_desc',
};

const INFLUENCERS_COUNTRIES: { [key: string]: string } = {
  Lithuania: 'Lithuania',
};

export interface InfluencerSearchFormInputs {
  tags?: string[];
  topics?: string[];
  priceRange?: number[];
  followersRangeIntervalKey?: string;
  followersRange?: number[];
  name?: string;
  platforms?: string[];
  hideWithoutPromotions?: boolean;
  includeBusinessProfiles?: boolean;
  showBarterProfiles?: boolean;
  sortBy?: string;
  sortByKey?: string;
}

export const InfluencerSearchForm = (
  {onSubmit, loading, submitDisabled}: FormProps<InfluencerSearchFormInputs>
) => {
  
  const priceRange = useGetPriceRange();
  const { responseData: topics } = useGetTopics(); 

  const {
    control,
    formState: { isValid },
    handleSubmit,
    getValues,
    setValue,
    trigger,
    reset,
    watch,
  } = useFormContext<InfluencerSearchFormInputs>();

  const [followersRangeIntervalKey, setFollowersRangeIntervalKey] = useState('');
  const [sortByKey, setSortByKey] = useState('');
  
  const resetSearchForm = () => {
    reset();
    handleSubmit(onSubmit)();
    trigger();
  }

  useEffect(() => {
    setFollowersRangeIntervalKey(getValues('followersRangeIntervalKey') || '')
  }, [watch('followersRangeIntervalKey')])

  useEffect(() => {
    setSortByKey(getValues('sortByKey') || 'Feedbacks Count ↓')
  }, [watch('sortByKey')])

  const onFollowersRangeIntervalKeyChange = (event: SelectChangeEvent<string>) => {
    setValue('followersRange', event.target.value ? INFLUENCER_FOLLOWERS_INTERVAL_DICT[event.target.value] : undefined);
    setValue('followersRangeIntervalKey', event.target.value);
    trigger(['followersRangeIntervalKey', 'followersRange']);
  }

  const onSortByKeyChange = (event: SelectChangeEvent<string>) => {
    setValue('sortBy', event.target.value ? INFLUENCERS_SORT_BY_DICT[event.target.value] : undefined);
    setValue('sortByKey', event.target.value);
    trigger(['sortByKey', 'sortBy']);
  }

  console.log(getValues('sortByKey'))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={5} paddingX='7%' marginY={1} alignItems='center' spacing={2}>
        <Grid item md={4} sm={6} xs={12} textAlign='center'>
          <InfluencerTextField
            label='Name'
            name='name'
            helperText={''}
            control={control}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12} textAlign='center'>
          <InfluencerAutocompleteWithTags
            disabled={loading}
            label="Tags"
            name="tags"
            control={control}
            options={[]} />
        </Grid>
        <Grid item md={4} sm={6} xs={12} textAlign='center'>
          <InfluencerAutocomplete
            disabled={loading}
            label="Topics"
            name="topics"
            control={control}
            options={topics?.map(topic => topic.name) || []} />
        </Grid>
        <Grid item md={4} sm={6} xs={12} textAlign='center'>
          <InfluencerSelect
            label='Followers Count Range'
            name='followersRangeIntervalKey'
            defaultValue={''}
            value={followersRangeIntervalKey}
            selectItems={Object.keys(INFLUENCER_FOLLOWERS_INTERVAL_DICT)}
            onChange={onFollowersRangeIntervalKeyChange}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12} textAlign='left'>
          <InfluencerCheckbox
            label='Hide Influencers Without Promotions'
            name='hideWithoutPromotions'
            control={control}
            tooltip="Hide profiles that haven't promoted any brands yet"
          />
          <InfluencerCheckbox
            label='Include Business Profiles'
            name='includeBusinessProfiles'
            control={control}
          />
          <InfluencerCheckbox
            label='Show Profiles That Accepts Barter'
            name='showBarterProfiles'
            control={control}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12} textAlign='center'>
          <InfluencerSlider
            label='Price Range'
            max={priceRange.responseData?.max}
            min={priceRange.responseData?.min}
            value={getValues('priceRange') as [number, number]}
            name='priceRange'
            setValue={(value) => {
                        setValue('priceRange', value);
                        trigger('priceRange');
                      }}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12} alignSelf='center' >
          <InfluencerSelect
            label='Sort By'
            name='sortByKey'
            value={sortByKey}
            selectItems={Object.keys(INFLUENCERS_SORT_BY_DICT)}
            onChange={onSortByKeyChange}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12} alignSelf='center'>
          <InfluencerSelect
            label='Country'
            name='country'
            disabled
            value={INFLUENCERS_COUNTRIES.Lithuania}
            selectItems={Object.values(INFLUENCERS_COUNTRIES)}
          />
        </Grid>
        <Grid item md={2} sm={12} xs={12} alignSelf='center'>
          <InfluencerButton
            color='secondary'
            label='Reset'
            loading={loading}
            onClick={resetSearchForm}
          />
        </Grid>
        <Grid item md={2} sm={12} xs={12} alignSelf='center'>
          <InfluencerSubmitButton
            label='Search'
            disabled={loading || submitDisabled || !isValid}
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}
