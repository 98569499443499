import axios, { Method } from 'axios';
import { openToast } from 'components';
import { useState, useEffect, useCallback } from 'react';
import { useUser } from 'shared/contexts';
import qs from 'qs';

interface ApiResponse<Request, Response, PathParams> {
  // eslint-disable-next-line no-unused-vars
  apiCall(request?: Request, successMessage?: string, pathParams?: PathParams, header?: Partial<Header>): Promise<Response>;
  loading: boolean;
  error: string | undefined;
  responseData: Response | undefined;
  responseItemsTotalCount: number | undefined;
}

interface Header {
  'user-access-token': string
}

const useGet = <Request, Response, PathParams = undefined>(url: string, payload?: Request, headers?: Partial<Header>, onDemand = false): ApiResponse<Request, Response, PathParams> => {
  return performRequest<Request, Response, PathParams>(url, 'GET', onDemand, headers, payload);
}

const usePost = <Request, Response, PathParams = undefined>(url: string, headers?: Partial<Header>, onDemand = true): ApiResponse<Request, Response, PathParams> => {
  return performRequest<Request, Response, PathParams>(url, 'POST', onDemand, headers);
}

const usePut = <Request, Response, PathParams = undefined>(url: string, headers?: Partial<Header>, onDemand = true): ApiResponse<Request, Response, PathParams> => {
  return performRequest<Request, Response, PathParams>(url, 'PUT', onDemand, headers);
}

const useDelete = <Request, Response, PathParams = undefined>(url: string, headers?: Partial<Header>, onDemand = true): ApiResponse<Request, Response, PathParams> => {
  return performRequest<Request, Response, PathParams>(url, 'DELETE', onDemand, headers);
}

const replacePathParams = (url: string, pathParams: Record<string, string | number>): string => {
  Object.keys(pathParams).forEach(param => {
    url = url.replace(`{${param}}`, encodeURIComponent(pathParams[param]));
  });
  return url;
};

const performRequest = <Request, Response, PathParams>
  (url: string, method: Method, onDemand: boolean, headers?: Partial<Header>, payload?: Request, successMessage?: string, pathParams?: PathParams)
  : ApiResponse<Request, Response, PathParams> => {
  const [responseData, setResponseData] = useState<Response>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [responseItemsTotalCount, setResponseItemsTotalCount] = useState<number>();
  const { user } = useUser();

  // TODO handle errors
  // TODO set default header on user login
  const apiCall = useCallback((payload?: Request, successMessage?: string, pathParams?: PathParams, header?: Partial<Header>) => {
    setLoading(true);
    return axios.request({
      data: method === 'GET' ? undefined : payload,
      headers: {
        'user-access-token': user.userAccessToken,
        ...header
      },
      method,
      url: pathParams ? replacePathParams(url, pathParams) : url,
      params: method === 'GET' ? payload : undefined,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, { indices: false })
        }
      }
    }).then(response => {
      setResponseData(response.data)
      setResponseItemsTotalCount(Number(response.headers['x-total-count']))
      successMessage && openToast(successMessage, 'success')
      return response.data
    }).catch(error => {
      setError(error)
      return Promise.reject(error)
    }).finally(() => setLoading(false))
  }, [user])

  useEffect(() => { onDemand ? setLoading(false) : apiCall(payload, successMessage, pathParams, headers) }, [])

  return { apiCall, error, loading, responseData, responseItemsTotalCount };
};

export { useGet, usePost, usePut, useDelete };
