import React from 'react';
import { Typography, Button, ButtonProps } from '@mui/material';


interface InfluencerTopMenuButton {
  disabled?: boolean
  label: string,
  Icon?: React.ElementType
  openInNewTab?: string
}

const InfluencerTopMenuButton = (props: InfluencerTopMenuButton & ButtonProps) => {
  const onClick = () => {
    if (props.openInNewTab) {
      window.open(props.openInNewTab, '_blank')
    }
  }

  return (
    <Button
      autoCapitalize='o'
      variant="text"
      color="inherit"
      sx={{ paddingX: 3 }}
      onClick={onClick}
      {...props}
    >
      {props.Icon && <props.Icon sx={{ marginRight: 1 }} />}
      <Typography variant='subtitle2'>
        {props.label}
      </Typography>
    </Button>
  )
}

export { InfluencerTopMenuButton }