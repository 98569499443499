import React from 'react';
import { Typography } from '@mui/material';
import { useUser } from 'shared/contexts';

export interface InfluencerInfoNoteProps {
  influencersCount: number;
}

export const InfluencerInfoNote = () => {
  const { user } = useUser()

  return (
    <Typography variant="body1" color="text.primary" textAlign="center" margin={10}>
      {user.name ? '*You have access to information on up to 112 influencers. To explore additional influencers, refine your search filters.'
        : '*You have access to information on up to 24 influencers. To explore additional influencers, login or refine your search filters.'}
    </Typography>
  );
}
