import { Container, Stack, Typography} from '@mui/material';
import { InfluencerCard } from 'components';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import React from 'react';
import { InfluencerTopMenuButton } from 'components/navigation/TopMenuButton';

export const ContactUsPage = () => {
   
  return (
    <Container maxWidth='sm'>
      <InfluencerCard
        hideDivider
      >
        <Stack spacing={2} p={2}>
          <Typography variant='h5'>Contact Us</Typography>
          <Typography variant='body1'>
            We value your feedback, questions, and concerns. We are here to assist you and ensure that your 
            experience with our influencer discovery and advertising platform is exceptional.
            <br />
            <br />
            The best way to reach us is through email
            <InfluencerTopMenuButton
              key='email'
              label='Info@TrendMyAd.com'
              href='mailto:info@trendmyad.com'
              Icon={MailOutlineIcon}
            />
            <br />
            <br />
            The best way to learn about updates is to follow us on
            <InfluencerTopMenuButton
              key='instagram'
              label='Instagram'
              Icon={InstagramIcon}
              openInNewTab='https://www.instagram.com/trendmyad/'
            />
          </Typography>
        </Stack>
      </InfluencerCard>
    </Container>
  );
}