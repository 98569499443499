import React from 'react';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerCard } from 'components';
import { useGetInfluencerPriceRangesForAdTypes } from 'shared/hooks';
import { InfluencerProfilePricesContentWithLoading } from './influencerProfilePricesContent';

export const InfluencerProfilePrices = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  const { responseData: priceRanges, loading: priceRangesLoading} = useGetInfluencerPriceRangesForAdTypes(influencerProfile._id)

  return (
    <InfluencerCard
      title='Prices'
    >
      <InfluencerProfilePricesContentWithLoading
        influencerProfile={influencerProfile}
        loading={priceRangesLoading}
        priceRanges={priceRanges}
      />
    </InfluencerCard>
  )
}