import React from 'react';
import { InfluencerAttributeValuePair, InfluencerCard, InfluencerRating, InfluencerSubmitButton } from 'components';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { GetInfluencerFeedbackResponse } from 'shared/hooks/requests/useInfluencerFeedbacks';
import { withLoading } from 'shared/hoc';
import { useUser } from 'shared/contexts';

interface InfluencerProfileFeedbackGridProps {
  // eslint-disable-next-line no-unused-vars
  handleFeedbackDeletion: (feedbackId: string) => unknown;
  influencerFeedback?: GetInfluencerFeedbackResponse[];
}

const InfluencerProfileFeedbackGrid = (props: InfluencerProfileFeedbackGridProps) => {
  const { handleFeedbackDeletion, influencerFeedback } = props;

  const { user } = useUser() 

  return (
    <>
      {influencerFeedback?.map((feedbackItem) => (
        <Grid item md={4} sm={6} xs={12} textAlign='center' key={`feedback-box-${feedbackItem._id}`}>
          <InfluencerCard
            hideDivider={true}
            variant='subcard'
            subtitleContent={
              user.isAdmin && <InfluencerSubmitButton onClick={() => handleFeedbackDeletion(feedbackItem._id)} label={'X'} fullWidth={false} sx={{alignSelf: 'flex-end'}}/>
            }
          >
            {feedbackItem.rating && <InfluencerRating readOnly={true} value={feedbackItem.rating} />}
            {feedbackItem.rating && <Divider sx={{ mt: 2, mb: 2 }} />}
            
            <InfluencerAttributeValuePair
              value={feedbackItem.comment}
              variant='longText' 
            />
            {feedbackItem.comment && <Divider sx={{ mt: 2, mb: 2 }} />}

            {feedbackItem.acceptsBarter && <Typography variant='subtitle2' display='inline-block' paddingRight={1}>Influencer accepted barter</Typography>}
            {feedbackItem.acceptsBarter && <Divider sx={{ mt: 2, mb: 2 }} />}

            {feedbackItem.prices?.map(price => (
              <Box key={price.adType}>
                <InfluencerAttributeValuePair
                  attribute={price.adType}
                  spaceBetween
                  value={price.value || ' -'}
                  variant='money'
                />
              </Box>
            ))}
            {!!feedbackItem.prices.length && <Divider sx={{ mt: 2, mb: 2 }} />}

            <InfluencerAttributeValuePair
              value={feedbackItem.timestamp}
              justifyContent='end'
              variant='date'
            />
          </InfluencerCard>
        </Grid>
      ))}
    </>
  )
}

export const InfluencerProfileFeedbackGridWithLoading = withLoading(InfluencerProfileFeedbackGrid)