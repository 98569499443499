import { usePost, useGet, useDelete } from 'shared/utils/api';

const BASE_API = `${process.env.REACT_APP_BASE_API}/influencers/{influencerId}/feedbacks`;
const generateApi = (influencerId: string) => BASE_API.replace('{influencerId}', influencerId)

interface InfluencerPrice {
  adTypeId: string;
  adType?: string;
  value?: number;
};

interface PostInfluencerFeedbackRequest {
  acceptsBarter?: boolean,
  prices: InfluencerPrice[],
  comment?: string,
  rating?: number,
};

interface GetInfluencerFeedbackResponse {
  _id: string
  acceptsBarter?: boolean,
  prices: InfluencerPrice[]
  comment?: string,
  rating?: number,
  timestamp: string,
};

interface GetInfluencerFeedbackRequestParams {
  limit: number,
  skip: number
};

interface DeleteInfluencerFeedbackPathParams {
  feedbackId: string
};

const useGetInfluencerFeedback =
  (influencerId: string, requestParams: GetInfluencerFeedbackRequestParams) =>
    useGet<GetInfluencerFeedbackRequestParams, GetInfluencerFeedbackResponse[]>(`${generateApi(influencerId)}/`, requestParams);

const usePostInfluencerFeedback = (influencerId: string) => usePost<PostInfluencerFeedbackRequest, undefined>(`${generateApi(influencerId)}/`);

const usePostInfluencerFeedbacks = (influencerId: string) => usePost<PostInfluencerFeedbackRequest[], undefined>(`${generateApi(influencerId)}/bulk/`);

const useDeleteInfluencerFeedback = (influencerId: string) => useDelete<undefined, undefined, DeleteInfluencerFeedbackPathParams>(`${generateApi(influencerId)}/{feedbackId}/`);

export { useGetInfluencerFeedback, usePostInfluencerFeedback, usePostInfluencerFeedbacks, useDeleteInfluencerFeedback, GetInfluencerFeedbackResponse };