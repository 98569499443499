import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Paper } from '@mui/material';
import { GetInfluencersSummariesRequest, useGetInfluencersSummaries } from 'shared/hooks';
import { InfluencerSearchForm, InfluencerSearchFormInputs } from '../influencerSearchForm';
import { FormProvider, useForm } from 'react-hook-form';
import { InfluencerSummaryCard } from '../../components/influencerCard';
import { InfluencerPagination } from 'components';
import useLocalStorageState from 'use-local-storage-state'
import { InfluencerInfoNote } from '../influencerInfoNote';
import { useUser } from 'shared/contexts';

const PAGE_SIZE = 16

export const InfluencerSearch = () => {
  const { user } = useUser()
  
  const [influencerSearchFilter, setInfluencerSearchFilter] = useLocalStorageState<GetInfluencersSummariesRequest>('influencerSummariesRequest', {
    defaultValue: {skip: 0, limit: PAGE_SIZE}
  })
  
  const {
    apiCall: getInfluencersSummaries,
    responseData: influencersSummaries,
    loading: influencersSummariesLoading,
    responseItemsTotalCount: influencersSummariesTotalCount
  } = useGetInfluencersSummaries();

  const formMethods = useForm<InfluencerSearchFormInputs>({mode: 'all'});

  const [currentInfluencersSummariesPage, setCurrentInfluencersSummariesPage] = useState(1);

  const getInfluencersSummariesForSpecificPage = (page?: number, searchFormInputs?: InfluencerSearchFormInputs) => {   
    setCurrentInfluencersSummariesPage(page || ((influencerSearchFilter.skip / PAGE_SIZE) + 1));

    const newInfluencerSearchFilter = {
      skip: page ? (PAGE_SIZE * (page - 1)) : influencerSearchFilter.skip,
      limit: PAGE_SIZE,
      tags: searchFormInputs ? searchFormInputs.tags : influencerSearchFilter?.tags,
      topics: searchFormInputs ? searchFormInputs.topics : influencerSearchFilter?.topics,
      minPrice: searchFormInputs ? searchFormInputs.priceRange?.[0] : influencerSearchFilter?.minPrice,
      maxPrice: searchFormInputs ? searchFormInputs.priceRange?.[1] : influencerSearchFilter?.maxPrice,
      name: searchFormInputs ? searchFormInputs.name : influencerSearchFilter?.name,
      minFollowers: searchFormInputs ? searchFormInputs.followersRange?.[0] : influencerSearchFilter?.minFollowers,
      maxFollowers: searchFormInputs ? searchFormInputs.followersRange?.[1] : influencerSearchFilter?.maxFollowers,
      hideWithoutPromotions: searchFormInputs ? searchFormInputs?.hideWithoutPromotions : influencerSearchFilter?.hideWithoutPromotions,
      includeBusinessProfiles: searchFormInputs? searchFormInputs?.includeBusinessProfiles : influencerSearchFilter?.includeBusinessProfiles,
      showBarterProfiles: searchFormInputs? searchFormInputs?.showBarterProfiles : influencerSearchFilter?.showBarterProfiles,
      followersRangeIntervalKey: searchFormInputs ? searchFormInputs.followersRangeIntervalKey : influencerSearchFilter?.followersRangeIntervalKey,
      sortBy: searchFormInputs ? searchFormInputs.sortBy : influencerSearchFilter?.sortBy,
      sortByKey: searchFormInputs ? searchFormInputs.sortByKey : influencerSearchFilter?.sortByKey
    }

    getInfluencersSummaries(newInfluencerSearchFilter)
      .then(() => { setInfluencerSearchFilter(newInfluencerSearchFilter) });
  }

  const handleSearch = (formValues: InfluencerSearchFormInputs) => {
    getInfluencersSummariesForSpecificPage(1, formValues)
  }

  const handlePageChange = (page: number) => {
    getInfluencersSummariesForSpecificPage(page);
  }

  useEffect(() => {
    formMethods.setValue('tags', influencerSearchFilter.tags)
    formMethods.setValue('topics', influencerSearchFilter.topics)
    formMethods.setValue('priceRange', influencerSearchFilter.minPrice && influencerSearchFilter.maxPrice ? [influencerSearchFilter.minPrice, influencerSearchFilter.maxPrice] : undefined)
    formMethods.setValue('name', influencerSearchFilter.name)
    formMethods.setValue('followersRange', influencerSearchFilter.minPrice && influencerSearchFilter.maxPrice ? [influencerSearchFilter.minPrice, influencerSearchFilter.maxPrice] : undefined)
    formMethods.setValue('followersRangeIntervalKey', influencerSearchFilter.followersRangeIntervalKey)
    formMethods.setValue('hideWithoutPromotions', influencerSearchFilter.hideWithoutPromotions)
    formMethods.setValue('includeBusinessProfiles', influencerSearchFilter.includeBusinessProfiles)
    formMethods.setValue('showBarterProfiles', influencerSearchFilter.showBarterProfiles)
    formMethods.setValue('sortBy', influencerSearchFilter.sortBy)
    formMethods.setValue('sortByKey', influencerSearchFilter.sortByKey)

    getInfluencersSummariesForSpecificPage()
  }, [])

  useEffect(() => {
    user.name ? handlePageChange(currentInfluencersSummariesPage)
      : handlePageChange(1)
  }, [user.name])

  return (
    <Paper elevation={0} sx={{p: 2}}>
      <FormProvider {...formMethods}>
        <InfluencerSearchForm
          onSubmit={handleSearch}
          loading={influencersSummariesLoading}
        />
      </FormProvider>
      <Grid
        container
        rowSpacing={9}
        columnSpacing={5}
        marginTop={0}
        paddingX='8%'
        marginBottom={10}
        justifyContent={influencersSummariesLoading ? 'center' : 'left'}
      >
        {influencersSummaries && !influencersSummariesLoading
          ? influencersSummaries.map((influencerSummary, index) => (
            <InfluencerSummaryCard
              key={index}
              influencerSummary={influencerSummary}
            />
          )) : <CircularProgress />}
      </Grid>
      <InfluencerPagination
        totalItems={influencersSummariesTotalCount || PAGE_SIZE}
        pageSize={PAGE_SIZE}
        currentPage={currentInfluencersSummariesPage}
        lastPageElement={<InfluencerInfoNote />}
        onPageChange={handlePageChange}
      />
    </Paper>
  );
}
