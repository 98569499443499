import React from 'react';
import { Box, Stack } from '@mui/material';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerAvatar, InfluencerCard, InfluencerLink } from 'components';

export const InfluencerProfileGeneralInfo = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  return (
    <InfluencerCard
      country={influencerProfile.country}
      title={influencerProfile.username}
      titleHelper={influencerProfile.avgRating}
      titleImageSrc={influencerProfile.linkToProfilePicture}
      subtitleContent={(
        <Stack direction='row' justifyContent='space-between' width={1} spacing={5}>
          <InfluencerLink hrefToOpenInNewTab={ influencerProfile.linkToProfile } label={`${influencerProfile.platformType} Profile`}/>
          <InfluencerLink hrefToOpenInNewTab={ influencerProfile.website } label='Website'/>
        </Stack>
      )}
    >
      {influencerProfile.topics.map((topic) => (
        <Stack direction='row' justifyContent='flew-start' alignItems='center' key={topic} spacing={3} width={1}>
          <InfluencerAvatar
            size='medium'
            src={`${process.env.PUBLIC_URL}/images/influencerTopics/${topic}.jpg`}
            alt={`${influencerProfile.username} profile picture`}
          />
          <Box>{topic}</Box>
        </Stack>
      ))}
    </InfluencerCard>
  )
}