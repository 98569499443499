import React, { useEffect } from 'react';
import { InfluencerButton, InfluencerCheckbox, InfluencerDialogSelect, InfluencerMultilineTextField, InfluencerPriceField, InfluencerRating, InfluencerSubmitButton } from 'components';
import { FormProps } from 'shared/utils';
import { GetAdTypesResponse } from 'shared/hooks';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Card, CardContent, Divider, Grid, Stack, useTheme } from '@mui/material';

interface InfluencerPrice {
  adTypeId: string;
  value?: number;
};

export interface InfluencerProfileFeedbackFormInputs {
  acceptsBarter?: boolean;
  prices?: InfluencerPrice[];
  rating?: number;
  comment?: string;
}

interface InfluencerProfileFeedbackFormProps {
  adTypes: GetAdTypesResponse[]
}

export const InfluencerProfileFeedbackForm = (props: FormProps<InfluencerProfileFeedbackFormInputs> & InfluencerProfileFeedbackFormProps) => {
  const { adTypes, onSubmit, loading } = props;

  const theme = useTheme()

  const {
    control,
    handleSubmit, 
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
    watch,
    trigger,
  } = useFormContext<InfluencerProfileFeedbackFormInputs>();

  const { fields: prices, append, remove } = useFieldArray({
    control,
    name: 'prices'
  });

  useEffect(() => {
    // TODO it is needed to refresh the form when checkbox changed. Might be issues with component itself
  }, [watch('acceptsBarter')])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack alignItems='center' rowGap={2} mt={2}>
        <Stack alignItems='center' rowGap={3} p={1} width={1} border={`solid 1px ${theme.palette.grey[400]}`} borderRadius={1}>
          <InfluencerCheckbox
              label='Influencer accepted barter'
              name='acceptsBarter'
              control={control}
              tooltip="Influencer accepts product instead of monetary payment"
          />
          <Grid container spacing={2}>
            {prices.map((_item, index) => (
              <Grid item md={4} sm={6} xs={12} textAlign='center' key={`price-box-${index}`}>
                <Card>
                  <CardContent>
                    <InfluencerDialogSelect
                      dialogCardDescriptions={adTypes?.map(adType => adType.description) || []}
                      dialogCardHeaders={adTypes?.map(adType => adType.name) || []}
                      dialogCardKeys={adTypes?.map(adType => adType._id) || []}
                      dialogTitle={'Pick one of the ad types'}
                      disabled={loading}
                      errors={errors.prices?.[index]?.adTypeId}
                      isRequired
                      label='Ad Type'
                      name={`prices.${index}.adTypeId`}
                      register={register}
                      setValue={(adTypeId) => {
                        setValue(`prices.${index}.adTypeId`, adTypeId);
                        trigger(`prices.${index}.adTypeId`);
                      }}
                    />
                    {(!getValues('acceptsBarter') && getValues(`prices.${index}.adTypeId`))
                      ? <InfluencerPriceField
                        disabled={loading}
                        errors={errors.prices?.[index]?.value}
                        label='Price'
                        name={`prices.${index}.value`}
                        register={register}
                      /> 
                      : null
                    }
                    <InfluencerButton
                      label='Remove'
                      loading={props.loading}
                      onClick={() => remove(index)}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box width={{xs: 1, sm: 0.4}}>
            <InfluencerButton
              label='Add Influencer Ad Types'
              loading={loading}
              onClick={() => append({adTypeId: ''})}
            />
          </Box>
        </Stack>
        <Divider flexItem/>
        <InfluencerRating
          control={control}
          disabled={loading}
          errors={errors.rating}
          label='Overall rating'
          name='rating'
        />
        <InfluencerMultilineTextField
          disabled={loading}
          errors={errors.comment}
          label='Comment'
          name='comment'
          register={register}
        />
        <InfluencerSubmitButton
          disabled={!isValid}
          loading={loading}
        />
      </Stack>
    </form>
  )
}