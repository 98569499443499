import { AxiosResponse } from 'axios';
import { openToast } from 'components';

export const genericErrorHandler = (onAuthError: () => void, response?: AxiosResponse, errorCode?: string) => {
  const displayErrorMessage = (message: string) => {
    openToast(message, 'error');
  };

  if (response?.status == 429 || errorCode === 'ERR_NETWORK') {
    displayErrorMessage(
      'Too many requests.'
    );
    onAuthError()
  } else if (!response|| response?.status >= 500 || (response?.config.method === 'get' && ![441, 429].includes(response?.status))) {
    displayErrorMessage(
      response?.data?.detail ||
      'Something went wrong, please refresh the page.'
    );
  } else if (response?.status == 441) {
    displayErrorMessage(
      'Please login again.'
    );
    onAuthError()
  } else {
    displayErrorMessage(
      (response?.data?.detail && typeof response.data.detail === 'string' && response.data.detail) ||
      'Something went wrong, please submit action again.'
    );
  }

  return Promise.reject(response?.data?.detail || 'Something went wrong')
};
