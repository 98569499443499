import React from 'react';
import { InfluencerAttributeValuePair } from 'components';
import { InfluencerPriceRangesForAdTypesResponse } from 'shared/hooks';
import { Box, Divider, Typography } from '@mui/material';
import { formatMoney } from 'shared/utils';
import { withLoading } from 'shared/hoc';
import { InfluencerProfile } from 'shared/contexts/influencerProfileContext/influencerProfileContextTypes';

interface InfluencerProfilePricesContentProps {
  influencerProfile: InfluencerProfile,
  priceRanges?: InfluencerPriceRangesForAdTypesResponse[]
}

const InfluencerProfilePricesContent = (props: InfluencerProfilePricesContentProps) => {
  const { priceRanges, influencerProfile } = props

  return (
    <>
      {influencerProfile.acceptsBarter && <Typography variant='subtitle2'>Influencer accepts barter</Typography>}
      {influencerProfile.acceptsBarter && <Divider sx={{ mt: 2, mb: 1 }} />}
      {priceRanges?.map(priceRange => (
        <Box key={priceRange.adType}>
          <InfluencerAttributeValuePair
            attribute={priceRange.adType}
            spaceBetween
            value={priceRange.minPrice && priceRange.maxPrice ? `${formatMoney(priceRange.minPrice)} - ${formatMoney(priceRange.maxPrice)}` : `${formatMoney()}`}
          />
        </Box>
      ))}
    </>
  )
}

export const InfluencerProfilePricesContentWithLoading = withLoading(InfluencerProfilePricesContent)